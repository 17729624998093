html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root {
  background-color: #f8f8f8;
  overflow: hidden;
}

.App {
  height: 100%;
}

p {
  margin: 0;
}
